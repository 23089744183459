import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import { COMP_INFO } from '../../config';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: COMP_INFO.name,
    children: [
      // { name: 'About us', href: PATH_PAGE.about },
      { name: 'Request Demo', href: PATH_PAGE.contact },
      { name: 'Pricing', href: PATH_PAGE.pricing },
      // { name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.termsAndConditions },
      { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: COMP_INFO.contactEmail, href: `mailto:${COMP_INFO.contactEmail}?subject=${COMP_INFO.contactEmail} question' `},
      // { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
    ],
  },
];


export const _socials = [
  // {
  //   value: 'facebook',
  //   name: 'FaceBook',
  //   icon: 'eva:facebook-fill',
  //   color: '#1877F2',
  //   path: 'https://www.facebook.com/caitlyn.kerluke',
  // },
  // {
  //   value: 'instagram',
  //   name: 'Instagram',
  //   icon: 'ant-design:instagram-filled',
  //   color: '#E02D69',
  //   path: 'https://www.instagram.com/caitlyn.kerluke',
  // },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: 'https://www.linkedin.com/company/data-fluent',
  },
  // {
  //   value: 'twitter',
  //   name: 'Twitter',
  //   icon: 'eva:twitter-fill',
  //   color: '#00AAEC',
  //   path: 'https://www.twitter.com/caitlyn.kerluke',
  // },
];


const DF_TAGLINE = "The no-code web scraping tool to easily stay ahead of the competition by monitoring any market and generating valuable business insights."
const CC_TAGLINE = "The all in 1 recruitment tool that takes care of finding and matching any new vacancies posted from any company website, to any potential candidate"


// ----------------------------------------------------------------------

export default function Footer() {

  const tagline = COMP_INFO.name === "Crawl Recruit" ?  CC_TAGLINE : DF_TAGLINE

  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
            {tagline}
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      to={link.href}
                      component={RouterLink}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {`© 2022 ${COMP_INFO.name}`}
        </Typography>
      </Container>
    </Box>
  );

}
