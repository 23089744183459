// routes
import { PATH_PAGE } from '../../../routes/paths';


// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  // {
  //   title: 'About us',
  //   icon: <Iconify icon="ic:round-grain" />,
  //   path: PATH_PAGE.about,
  // },

  { 
    title: 'Pricing', 
    icon: <Iconify icon="eva:file-fill" />, 
    path: PATH_PAGE.pricing 
  },
  // { 
  //   title: 'FAQs', 
  //   icon: <Iconify icon="eva:book-open-fill" />, 
  //   path: PATH_PAGE.faqs 
  // },
//   { title: 'Contact us',    icon: <Iconify icon="ic:round-grain" />,
// 
//   path: PATH_PAGE.contact },


];

export default navConfig;
