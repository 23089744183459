import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"         viewBox="0 0 375 374.999991" 
>
        <defs>
          <linearGradient id="BG1" x1="50=%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" stopColor={'#7A5FFF'} >
              <animate attributeName="stop-color" values="#7A5FFF; #01FF89; #7A5FFF" dur="4s" repeatCount="indefinite"/>
            </stop>

            <stop offset="100%" stopColor={'#01FF89'} >
              <animate attributeName="stop-color" values="#01FF89; #7A5FFF; #01FF89" dur="4s" repeatCount="indefinite"/>
            </stop>
          </linearGradient>



          {/* <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient> */}

        </defs>

        <g >
          <path
            fill="url(#BG1)"
            d="M 140.140625 281.019531 C 151.175781 276.046875 160.695312 268.550781 168.277344 258.695312 
            C 175.773438 249.09375 181.585938 236.960938 185.546875 222.726562 C 189.421875 208.742188 191.441406 192.402344 
            191.441406 174.039062 C 191.441406 156.601562 189.421875 141.101562 185.546875 127.875 C 181.585938 114.398438 175.691406
             102.941406 168.191406 93.761719 C 160.527344 84.492188 151.09375 77.332031 139.972656 72.699219 C 129.105469 68.152344 
             116.808594 65.792969 103.332031 65.792969 L 30.464844 65.792969 L 30.464844 288.347656 L 103.332031 288.347656 C 116.808594
              288.347656 129.191406 285.902344 140.140625 281.019531 Z M 99.707031 114.820312 C 105.6875 114.820312 111.082031 115.828125
               115.714844 117.9375 C 120.347656 119.957031 124.136719 122.90625 127.421875 126.949219 C 130.792969 131.078125 
               133.402344 136.382812 135.257812 142.785156 C 137.195312 149.441406 138.121094 157.441406 138.121094 166.539062 
               L 138.121094 181.617188 C 138.121094 191.8125 137.109375 200.824219 135.171875 208.320312 C 133.320312 215.566406
                130.625 221.546875 127.253906 226.179688 C 123.96875 230.644531 120.09375 233.929688 115.546875 236.121094
                 C 110.910156 238.308594 105.605469 239.488281 99.792969 239.488281 L 82.441406 239.488281
                  L 82.441406 114.820312 Z M 99.707031 114.820312 M 211.242188 66 L 344.167969 66 L 344.167969 114.820312
                   L 211.242188 114.820312 Z M 211.242188 114.820312 L 263.21875 114.820312 L 263.21875 152.976562
                    L 211.242188 152.976562 Z M 211.242188 152.976562 L 332.375 152.976562 L 332.375 201.835938 L 211.242188
                     201.835938 Z M 211.242188 201.835938 L 263.21875 201.835938 L 263.21875 288 L 211.242188 288 Z M 211.242188 201.835938"
          />
        </g>

      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
